import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b985cfde = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _cbed384a = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _ff0b4496 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _7e07d5cf = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _9e8bcb50 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _0e6af81e = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _2e38a4b8 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _7cba4ad5 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _627afc7b = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _07042191 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _a0a484ec = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _fa12222c = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _409ccdce = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _bda98824 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _cc914cac = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _af30b1f0 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _7332ffd3 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _1f99baf0 = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _1091109c = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _f1a2179a = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _5c29b9f1 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _b985cfde,
    name: "kits"
  }, {
    path: "/lojas",
    component: _cbed384a,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _ff0b4496,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _7e07d5cf,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _9e8bcb50,
    name: "motos"
  }, {
    path: "/reset",
    component: _0e6af81e,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _2e38a4b8,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _7cba4ad5,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _627afc7b,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _07042191,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _a0a484ec,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _fa12222c,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _409ccdce,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _bda98824,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _cc914cac,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _af30b1f0,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _7332ffd3,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _1f99baf0,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _1091109c,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _f1a2179a,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _5c29b9f1,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
